.form-content {
    position: relative;
    &__text-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        text-align: center;
    }
}

.form-login__textfield {
    text-align: center;
}

.form-login__forgotten-pwd {
    width: 100%;
    text-align: left;
    text-decoration: none;
    font-size: .9em;
    margin-bottom: 10px;

}

.form-content__text-content {
    position: relative;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    text-align: center;

}

.form-content__content {
    padding: 40px;
    background-color: rgba(255, 255, 255, .95);
    border-radius: 4px;

}

.form-login__h1 {
    font-weight: 300;
    font-size: 56px;
    line-height: 60px;
 
}

.form-login__h2 {
    color: #333333;
    font-size: 1.5em;
    line-height: 28px;
    text-align: center;

}

.form-login__h3 {
    font-weight: 300;
}

.form-login__h4 {

    font-weight: 300;
}

.form-content__logo {
    font-weight: 300;
}

.form-content__title {
    font-weight: 300;
    font-size: 56px;
    line-height: 60px;
    margin-top: 10px;
    margin-bottom: 0;
}

.form-content__subtitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}