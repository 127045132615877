.menu-content {
   border: 1px solid salmon;
   width: 450px;
}

.main-content {
    width: 100% !important;
    min-width: 100%;
   
 }

 .MuiSvgIcon-root {
   // fontSize: '1.3em',
   // fontWeight: '500', lineHeight: '30px',
    width: 40;
    height: 40;
   // border: '1px solid #7c7980',
  }