$border: none;
/* debug, 2px solid, give some div visibility */
$colorLila: #ab273b;
$mainblue: chartreuse;

$borderA: 1px solid #000000; /* debug, give div visibility*/
$borderB: 1px solid #ab273b; /* debug, give div visibility*/
$borderC: 1px solid chartreuse; /* debug, give div visibility*/
$borderD: 1px solid blue; /* debug, give div visibility*/
$borderE: 1px solid orange; /* debug, give div visibility*/

  /* For general iPad layouts */
@media only screen and (device-width: 768px) {
  /* div {border: $borderA;}/**/
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  #no-portrait,
  #no-portrait * {
    visibility: visible;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* #no-portrait,  #no-portrait * { visibility: visible;}/**/
}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #no-portrait,
  #no-portrait * {
    visibility: visible;
  }
}

// Landscape Apple 10.2-inch iPad 9. gen.
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  /*div {border: $borderE;}/**/
  body {background: white;} 
  // Mixin
  @include styling($rowOffsett: -2.4, $colOffsett: 1, $rowHeight: 3.3, $colWidth: 1.3, $colLen: 1.31, $lenOffsett: 1);

  /* OVERRIDE  etpbms_stylesheet */
  input.mapfield {
    font-size: 1em;
    border: 1px solid grey;
    height: 3vh;
    padding-left: 4px;
    padding-right: 0;
  }
}

//Samsung GalaxyTab
@media only screen and (min-width: 1205px) and (max-height: 650px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  /*div {border: $borderE;}/**/
  // Mixin
  @include styling($rowOffsett: -2.5, $colOffsett: 0.5, $rowHeight: 3.1, $colWidth: 1.3, $colLen: 1.3, $lenOffsett: 0.8);

  /* OVERRIDE  etpbms_stylesheet */
  input.mapfield {
    font-size: 1em;
    border: 0px solid grey;
    height: '20px';
    padding-left: 3px;
    padding-right: 0;
  }
}
