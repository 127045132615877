@import './_colors';
$border: none;
/* debug, 2px solid, give some div visibility */


html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}


body {
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
}

.app-layout-header {
  height: 0;
}

.layout-content {
  height: 100vh;
}

.app-layout-footer {
  height: 0;
}

.lpNav {
  height: calc(100% - 64px);
}

img {
  max-width: 100%;
  height: auto;
}

header {
  display: flex;
  flex-direction: column;
}

label {
  //text-transform: uppercase;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}

div {
  border: $border;
}

a {
  background-color: transparent;
  color: #961932;
  text-decoration: none;
}

.ms-Button {
  display: 'none';
}

p {
  font-size: 10em;
}

h1 {
  font-weight: 300;
  font-size: 56px;
  line-height: 60px;
  margin-top: 10px;
  margin-bottom: 0;
}

h2 {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

H3 {
  font-size: 1.2em;
}

H4 {
  font-size: 1.2em;
}

H5 {
  font-size: 1.2em;
}

label {
  // text-transform: uppercase;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}

.centered {
  text-align: center;
}

.left:not(pre.left) {
  text-align: left;
}

.right:not(pre.right) {
  text-align: left;
}

.margin10 {
  margin: 10px;
}

.marginTopBottom5 {
  margin: 5px 0;
}

.marginTopBottom10 {
  margin: 10px 0;
}

.marginTopBottom15 {
  margin: 15px 0;
}

.marginTopBottom20 {
  margin: 20px 0;
}

.login-warning.red {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 5px;
  margin-top: 20px;
}

.row {
  display: flex;
}

/* Create two equal columns that sits next to each other */
.column {
  width: 100%;
  flex: 50%;
}

.maxWidth {
  width: 100%;
}

.accounting-datagrid {
  background-color: #fff !important;
  padding: 10px;
  width: 100%; //fallback
  width: 80vw;
  height: 100%;
  height: 800px;
}

.accounting-datagrid-wrapper {
  background-color: #fff !important;
  padding: 10px;
  width: 100%; //fallback
  width: 80vw;
  height: 100%;
  height: 700px;
}

.accounting-datepicker-wrapper {
  background-color: #fff !important;
  padding: 10px;
  width: 100%; //fallback
  width: 80vw;
  height: 60px;
}

.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}

.react-grid-item {
  background-color: #dddddd;
}

.pointer {
  cursor: pointer;
}

textarea {
  resize: none;
}

#no-portrait,
#no-portrait * {
  visibility: hidden;
}
#no-portrait {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 50000;
  background-color: #333;
}

#chg-landscape {
  position: relative;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  text-align: center;
  color: wheat;
  font-size: 2em;
}